import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    {
      path: '/khu-vuc',
      name: 'khu-vuc',
      component: () => import('@/views/khu-vuc/index'),
    },
    {
      path: '/khach-hang',
      name: 'khach-hang',
      component: () => import('@/views/users/khach-hang/index'),
    },
    {
      path: '/dai-ly',
      name: 'dai-ly',
      component: () => import('@/views/users/dai-ly/index'),
    },
    {
      path: '/y-nghia-cac-con-so',
      name: 'y-nghia-cac-con-so',
      component: () => import('@/views/y-nghia-cac-con-so/list/index'),
    },
    {
      path: '/them-y-nghia-cac-con-so',
      name: 'them-y-nghia-cac-con-so',
      component: () => import('@/views/y-nghia-cac-con-so/form/YNghiaCacConSoForm'),
    },
    {
      path: '/sua-y-nghia-cac-con-so/:id',
      name: 'sua-y-nghia-cac-con-so',
      component: () => import('@/views/y-nghia-cac-con-so/form/YNghiaCacConSoForm'),
    },
    {
      path: '/xem-chi-tiet-y-nghia-con-so/:id',
      name: 'xem-chi-tiet-y-nghia-con-so',
      component: () => import('@/views/y-nghia-cac-con-so/view/ChiTiet'),
    },
    {
      path: '/xem-chi-tiet-dai-ly/:id',
      name: 'xem-chi-tiet-dai-ly',
      component: () => import('@/views/users/dai-ly/view/index'),
    },
    {
      path: '/view-tree-dai-ly',
      name: 'view-tree-dai-ly',
      component: () => import('@/views/users/dai-ly/tree-dai-ly/index'),
    },
    {
      path: '/xem-bai/:id',
      name: 'xem-bai',
      component: () => import('@/views/users/khach-hang/xem-bai/index'),
    },
    {
      path: '/giao-dich',
      name: 'giao-dich',
      component: () => import('@/views/giao-dich/index'),
    },
    {
      path: '/thong-ke',
      name: 'thong-ke',
      component: () => import('@/views/thong-ke/index'),
    },
    {
      path: '/thanh-vien',
      name: 'thanh-vien',
      component: () => import('@/views/users/thanh-vien/index'),
    },
    {
      path: '/vai-tro',
      name: 'vai-tro',
      component: () => import('@/views/vai-tro/index'),
    },
    {
      path: '/chuc-nang',
      name: 'chuc-nang',
      component: () => import('@/views/chuc-nang/index'),
    },
    {
      path: '/phan-quyen',
      name: 'phan-quyen',
      component: () => import('@/views/phan-quyen/index'),
    },
    {
      path: '/cau-hinh',
      name: 'cau-hinh',
      component: () => import('@/views/cau-hinh/index'),
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/users/profile/form'),
    },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
const DEFAULT_TITLE = 'Tinh hoa thần số'
router.afterEach(to => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role_user : null))
  }

  return next()
})

export default router
